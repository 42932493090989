<template>

  <a-card :title="'快速配置名称：' + fastInfo.name" :bordered="false">
    <div slot="extra">
      <a-button v-if="queryParam.centerId==fastInfo.centerId" type="primary" icon="edit" @click="handleAdd" style="float:right">编辑</a-button>
      <a-button icon="left" @click="goBack" style="float:right; margin-right:15px;">返回</a-button>
    </div>
    <a-table
            :columns="column"
            :dataSource="fastInfo.fastConfigurationInfoEntities"
            :pagination="pagination"
            rowKey="id"
    >
      <template v-for="col in ['detectName', 'methodName']" :slot="col" slot-scope="text, record, index">
        <a-input
                :key="col"
                v-if="record.editable"
                style="margin: -5px 0"
                :value="text"
                @change="e => tableChange(e.target.value, col, index)"
        />
        <template v-else>{{ text }}</template>
      </template>
    </a-table>
  </a-card>
</template>

<script>
import { getFastDetail } from '@/api/quality/item'

export default {
  name: 'TableList',
  components: {
  },
  data () {
    return {
      pagination: {
        total: 0,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '15', '20'],
        showTotal: total => `共有 ${total} 条数据`
      },
      // create model
      visible: false,
      // 查询参数
      queryParam: {
        id: this.$route.query.id
      },
      // 返回数据
      fastInfo: {},
      // table
      column: [
        {
          title: '#',
          dataIndex: 'index',
          key: 'index',
          width: '10%'
        },
        {
          title: '检测项名称',
          dataIndex: 'detectName',
          key: 'detectName',
          width: '40%',
          scopedSlots: { customRender: 'detectName' }
        },
        {
          title: '检测方法',
          dataIndex: 'methodName',
          key: 'methodName',
          width: '50%',
          scopedSlots: { customRender: 'methodName' }
        }
      ]
    }
  },
  filters: {},
  created () {
    let area = JSON.parse(localStorage.getItem("areaValue"));
    this.queryParam.centerId = area.depId

    this.getFastInfo()
  },
  methods: {
    // 请求
    getFastInfo () {
      getFastDetail({ id: this.$route.query.id }).then(response => {

        for (var i = 0;i<response.data.fastConfigurationInfoEntities.length;i++) {
          response.data.fastConfigurationInfoEntities[i].index = i + 1
        }

        this.fastInfo = response.data
      })
    },
    // 操作方法
    goBack () {
      this.$router.back()
    },
    handleAdd () {
      this.$router.push({
        path: '/test/setting/edit',
        query: {
          id: this.$route.query.id
        }
      })
    }
  }
}
</script>
